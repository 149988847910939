export default {
  "header": {
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "China": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "Laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "Turkiye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquia"])}
  },
  "footer": {
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
    "ICanMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira ICan"])},
    "ICanChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso conversar"])},
    "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriedades do ICan"])},
    "ServiceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordo de serviço"])},
    "PrivacyAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordo de privacidade"])},
    "QRPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso e Devolução"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda e suporte"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nós"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contate-nos"])},
    "SocilaMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permaneça conectado"])},
    "SaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixe o aplicativo"])}
  },
  "message": {
    "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucesso no login do usuário"])},
    "LoginToMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça login no iCan Mall"])},
    "SomethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado"])},
    "DataDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confira expirado!"])},
    "AddressAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço adicionado com sucesso"])},
    "DeleteAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir endereço com sucesso"])},
    "UpdateAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço modificado com sucesso"])},
    "ChangeAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega alterado"])},
    "CodeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código enviado"])},
    "ResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir sucesso"])},
    "AddFavourits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado aos favoritos"])},
    "ItemOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fora de estoque"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado ao carrinho"])},
    "LoggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectado"])},
    "PasswordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha atualizada com sucesso!"])},
    "ProfileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil atualizado com sucesso!"])},
    "OrderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido cancelado"])},
    "InvalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de celular inválido"])},
    "RegisterSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro realizado com sucesso"])},
    "NoFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem filtros"])},
    "FreeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega grátis na sua região"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, não há comentários aqui!"])},
    "prodnotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este produto não está disponível em sua região"])},
    "AddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por favor adicione seu endereço"])},
    "DelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir pedido com sucesso"])},
    "HaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["já tem uma conta?"])},
    "CODPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode pagar em dinheiro ou cartão bancário ao nosso transportador quando receber a mercadoria no seu endereço de entrega."])},
    "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido cancelado com sucesso"])},
    "onlyfiveImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode fazer upload de apenas cinco imagens"])},
    "EnterYourThought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por favor insira seus pensamentos"])},
    "CommentsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seus comentários foram adicionados com sucesso"])},
    "NoCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há nada para mostrar nesta categoria!"])},
    "ConfirmByCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depois de confirmar o pagamento, prosseguiremos com o cartão selecionado"])},
    "NoVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada para mostrar nesta categoria!"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucesso"])},
    "FormValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Parece que você perdeu alguns campos ou inseriu dados inválidos."])}
  },
  "headerTitle": {
    "ICanMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar Conta"])},
    "ChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bens"])},
    "IcanServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços iCan"])},
    "TrendingDeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas populares"])},
    "YouMayLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode gostar"])},
    "MyCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritos"])},
    "Myfootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha pegada"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "DefaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereço padrão"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
    "AccountSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança da conta"])},
    "PersonalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil pessoal"])},
    "PasswordSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança de senha"])},
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil pessoal"])},
    "PwdChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar a senha"])},
    "ProdDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do produto"])},
    "ProdSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["especificações do produto"])},
    "CusReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações de Clientes"])},
    "AbtSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre o vendedor"])},
    "SimilarProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos Similares"])},
    "AllReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as avaliações"])},
    "DeliveryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega"])},
    "OrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumo do pedido"])},
    "TotAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total"])},
    "PaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de Pagamento"])},
    "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])},
    "Addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços"])},
    "EditAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Endereço"])},
    "AddNewAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adicionar novo endereço"])},
    "ShoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho de compras"])},
    "CartEmplty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio"])},
    "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para você"])},
    "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "ToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
    "ToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
    "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
    "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especialidade"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História"])},
    "Popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
    "searchResultFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da busca por"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar Resultados"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos"])},
    "Shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojas"])},
    "CoinPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço da moeda"])},
    "CoinRrecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registros de moedas"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificação"])},
    "Invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu convite"])},
    "Categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
    "Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcas"])},
    "COD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar com COD?"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido feito"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de entrega"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de rastreio"])},
    "OrederCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar pedido"])},
    "RateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie um produto"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "Mall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Lanka PVT Ltd"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicativo"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você confirma o recibo"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus vouchers"])},
    "Trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As principais ofertas de tendências de hoje"])},
    "AppAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O aplicativo iCan Mall já está disponível"])},
    "confirmedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar e pagar"])},
    "SelectPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o método de pagamento"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões salvos"])}
  },
  "subTitles": {
    "DefaultAddrSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forneça mais informações"])},
    "IcanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economize e pague Reconheço que as informações do meu cartão são salvas no cofre do cartão bancário para transações subsequentes. Se necessário, você pode remover o cartão no \"Payment Options\" section in the \"Account\" menu."])},
    "LoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com e-mail"])},
    "LoginMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça login com celular"])},
    "Forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu sua senha?"])},
    "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira seus dados para criar sua conta"])},
    "RegisterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastre-se com e-mail"])},
    "RegisterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se com celular"])},
    "PleaseEnterMobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o número do celular cadastrado"])},
    "PleaceEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o e-mail cadastrado"])},
    "ResetWithMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir com celular"])},
    "ResetWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir com e-mail"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza?"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unid"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "Additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição e credenciais adicionais"])},
    "CancelledDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de cancelamento"])},
    "RateProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie seu produto"])}
  },
  "placeholeds": {
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "ShopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compre agora"])},
    "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar"])},
    "AddNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar novo"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "SaveConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar e confirmar"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça a encomenda"])},
    "SeeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])},
    "GetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter código"])},
    "ResetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
    "AddCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao carrinho"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar agora"])},
    "WriteReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escreva uma crítica"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidade"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
    "SaveCanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar alterações"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confira"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
    "DeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir tudo"])},
    "ContinueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue comprando"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nós"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linguagem"])},
    "ServiceAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordo de serviço"])},
    "PrivacyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acordo de privacidade"])},
    "ContactSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com o vendedor"])},
    "ViewLogistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Logística"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme o recebimento"])},
    "DeleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir pedido"])},
    "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie o produto"])},
    "CancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar pedido"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "ContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de contato"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Província"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
    "AddrL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de endereço"])},
    "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEP"])},
    "SearchProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar produtos"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja de visita"])},
    "confirmeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirme"])},
    "ViewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver pedido"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "SubmitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar uma revisão"])},
    "AccoutAndSecutiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança da conta"])},
    "Collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coletar"])},
    "DownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixe o aplicativo do shopping iCan"])},
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar pedido"])},
    "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
    "LoadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregue mais"])},
    "PasswdLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha de acesso"])},
    "QuickLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login rápido"])},
    "ShippinFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frete grátis"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])}
  },
  "formLabels": {
    "PersonalInfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações pessoais"])},
    "CountryRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País/Região"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "MobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telemóvel"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "NewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Senha"])},
    "ConfirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua senha"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de verificação"])},
    "DefaultAddrlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir como endereço padrão"])},
    "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apelido"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gênero"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aniversário"])},
    "Currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha atual"])},
    "InviteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Convite"])},
    "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estou de acordo com"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor cancelado"])},
    "CancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o motivo do cancelamento"])},
    "AddRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione seus pensamentos"])},
    "AddImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar imagens"])},
    "isAnonymus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie um produto como anônimo"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claro"])},
    "MobileOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de celular ou e-mail"])}
  },
  "Label": {
    "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENDIDO"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOUCHERS"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimar data de entrega"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "5Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 estrelas"])},
    "4Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 estrelas"])},
    "3Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 estrelas"])},
    "2Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 estrelas"])},
    "1Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 estrela"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "BasketCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cestas no carrinho"])},
    "ItemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do item"])},
    "TotalItemCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custo total do item"])},
    "SavedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvo por vouchers"])},
    "PromoCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Códigos promocionais"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de entrega"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar detalhes do cartão"])},
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])},
    "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "OrderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do pedido"])},
    "RefundAmmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia de reembolso"])},
    "Cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento na entrega"])},
    "InStok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em estoque"])},
    "OutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fora de estoque"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escaneie-me"])},
    "AppDisriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próxima revolução no comércio eletrônico com incríveis"])},
    "AppDisriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ofertas e preços inacreditáveis"])},
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os direitos reservados."])},
    "DefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endereço de entrega padrão"])},
    "NoAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço fornecido"])},
    "VocherCampain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo ao shopping ican! Pegue seus cupons exclusivos para novos usuários agora!🎉"])},
    "DownloadDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próxima revolução no e-commerce com ofertas incríveis e preços inacreditáveis."])},
    "UseAppDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use o aplicativo iCan Mall a qualquer hora e em qualquer lugar para encontrar itens, entrar em contato com fornecedores, gerenciar seus pedidos e efetuar pagamentos."])},
    "GetApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixe o aplicativo"])},
    "subTot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "ShipFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envio"])},
    "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Crédito / Débito"])},
    "Deleverytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prazo de entrega"])},
    "DeliveryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de entrega"])},
    "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Entrega Estimada"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirme"])},
    "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agendar entrega"])}
  },
  "ValidationMsg": {
    "FieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo é obrigatório"])},
    "SelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o código do país"])},
    "ValidNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira um número de celular válido"])},
    "EnterPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira a senha"])},
    "PWRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha não pode conter espaços iniciais ou finais"])},
    "OTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o código de verificação"])},
    "ValiedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira um código válido"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira a senha novamente"])},
    "PwNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha não corresponde"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o e-mail"])},
    "ValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira um e-mail válido"])},
    "MobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o número do celular"])},
    "ValidNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira o apelido"])},
    "PwTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha deve ter 8 caracteres e incluir pelo menos uma letra maiúscula, uma letra simples, um símbolo e um número."])},
    "AddrTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de caracteres não pode exceder 500"])}
  },
  "NotItemsMessage": {
    "notItemcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não existem itens no seu carrinho"])},
    "notItemTopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há itens a pagar"])},
    "notNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há notificações"])},
    "notItemToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há itens para entregar"])},
    "notSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe. Não encontramos nenhuma correspondência para o seu termo de pesquisa"])},
    "notFavourit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode adicionar um item aos seus favoritos clicando no ícone de coração"])},
    "notItemToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há itens para receber"])},
    "notCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há nenhum item em Concluído"])},
    "notFootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pegada está vazia!"])},
    "notItemRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há itens a serem reembolsados"])},
    "notReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, ainda não há comentários para este produto"])}
  },
  "CancelOrderReasons": {
    "Duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido duplicado"])},
    "WrongProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do produto errada"])},
    "ChangeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega alterado"])},
    "ChangeMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eu mudei de ideia"])},
    "FoundCheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrado mais barato em outro lugar"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O prazo de entrega é muito longo"])}
  },
  "invitationInfo": {
    "UnlockRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ScantoRefer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalize para indicar um amigo!🎉"])},
    "MyInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu convite"])},
    "Invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidamos"])},
    "YourRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua classificação"])},
    "confInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convite confirmado"])},
    "Leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre os melhores"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "Refferel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref."])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferência"])}
  },
  "voucherInfo": {
    "CappedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampado em"])},
    "PercentageDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% DESLIGADO"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "VoucherApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicado"])},
    "VoucherCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coletar"])},
    "VoucherCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coletado"])},
    "VoucherCollectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "VoucherCollectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "AllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "ProductVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produtos"])},
    "ShopVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "MallVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping iCan"])},
    "MyVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "RecommendedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])},
    "ViewAllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mais"])},
    "ShopVoucherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupom e desconto"])}
  },
  "campain": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horas"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutos"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundos"])}
  },
  "GroupBuy": {
    "groupBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de grupo"])},
    "NeedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precisar"])},
    "NoParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "timeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tempo esgotado"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dia"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundo"])},
    "InPeogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em andamento"])},
    "Upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por vir"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])}
  },
  "AddressMobile": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativa"])},
    "enterAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira um número alternativo"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "selectArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a província, distrito, cidade"])},
    "yourArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione sua área"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecione"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglês"])},
    "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chinês"])},
    "Turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turca"])},
    "Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russa"])},
    "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])}
  },
  "BannerText": {
    "BannerBottomHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Your Shopping Adventure!"])},
    "BannerBottomSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app now to discover electronics, fashion experiences, amazing offers, and more!"])},
    "BannerBottomSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the Qr code to download iCan Mall"])}
  },
  "OrderDetails": {
    "OrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier service"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "OrderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "PlacedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed on"])},
    "PaidOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery insurance"])},
    "ShopDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop discount"])},
    "SeeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "SeeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "SubmitOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit order"])},
    "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
    "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copied"])}
  },
  "ContactUs": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time and fast responses"])},
    "NeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "ContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact iCan mall Customer service for help."])},
    "OfficeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Address"])},
    "SocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also available on social media"])}
  }
}