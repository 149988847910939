import slugify from "slugify";

export const slugifyUrl = (title, id) => {
    // console.log("Original title:", title);
    // console.log("ID:", id);

    // Replace unwanted characters
    let sanitizedTitle = title
        .replace(/[,°#%=\\[\]|–"]/g, "") // Remove unwanted characters
        .replace(/\?/g, "") // Remove question marks
        .replace(/_/g, "-") // Replace underscores with hyphens
        .replace(/\s+/g, "-") // Replace whitespace with a single hyphen
        .replace(/[*+~)(!:@/）（]/g, "-") // Replace special characters with hyphens
        .replace(/[. '`″]/g, "") // Remove periods, backticks, spaces
        .replace(/--+/g, "-")
        .replace(/ – /g, "-")
        .replace(/&/g, "and"); 

    // console.log("Sanitized title:", sanitizedTitle);

    // Combine the sanitized title with the ID
    let baseUrl = sanitizedTitle + "-" + id;
    // console.log("Base URL before slugify:", baseUrl);

    // Slugify the URL
    let sluggedUrl = slugify(baseUrl, {
        replacement: "-",
        lower: true,
        remove: /[*~.,#=()\\[\]{}'"!:@’?°″;]/g // Remove special characters
    }).replace(/_/g, "-");

    // console.log("Slugged URL after slugify:", sluggedUrl);

    // Remove leading and trailing hyphens
    sluggedUrl = sluggedUrl.replace(/^-+/, "").replace(/-+$/, "");
    // console.log("Final slugged URL:", sluggedUrl);

    return sluggedUrl;
};

// export const slugifyUrl = (title, id) => {
//     // Replace % and | with a hyphen (-)
//     const sanitizedTitle = title.replace(/[%|]/g, "-");

//     // Combine the sanitized title with the ID
//     const baseUrl = sanitizedTitle + "-" + id;

//     // Slugify the URL
//     return slugify(baseUrl, {
//         replacement: "-",
//         lower: true,
//         remove: /[*+~.,#=()\\[\]{}'"!:@/?°″]/g  // Added ″ to remove inch symbol
//     }).replace(/_/g, "-");
// }





export const unslugifyUrl = (slug) => {
    const parts = slug.split('-');
    const id = parts.pop();
    const title = parts.join(' ');
    return { title, id: Number(id) };
}

export const getIdFromUrl = (url) => {
    const titles = url?.split("-");
    const id = titles && titles.length > 0 ? titles[titles.length - 1] : null;
    return id;
}

